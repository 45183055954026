import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import SectionContainer from '../components/section-container'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO />
    <div className="hidden sm:block pt-16 mb-12">
      <div className="w-1/3 bg-brand-brown h-12"></div>
      <div className="bg-brand-gray text-white ">
        <div className="container">
          <div className="flex items-center -mx-2">
            <div className="w-5/12 px-2">
              <p className="text-2xl text-center">
                <span
                  style={{
                    fontSize: '4.5rem',
                    fontFamily: 'Arial',
                    lineHeight: 0,
                    position: 'relative',
                    top: '1.75rem',
                    right: '0.25rem',
                  }}
                >
                  “
                </span>
                Likumi ir jāievēro,
                <br />
                bet, lai tos ievērotu,
                <br />
                tie ir jaizprot!
                <span
                  style={{
                    fontSize: '4.5rem',
                    fontFamily: 'Arial',
                    lineHeight: 0,
                    position: 'relative',
                    top: '1.75rem',
                    left: '0.25rem',
                  }}
                >
                  ”
                </span>
              </p>
            </div>
            <div className="w-7/12 px-2">
              <div className="relative -mt-28 -mb-12">
                <Img fluid={data.file.childImageSharp.fluid} />
                <div className="absolute right-0 bottom-0 text-3xl font-medium leading-none pb-8 pr-4">
                  Diāna Grīnberga
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/3 bg-brand-brown h-12 ml-auto mr-12"></div>
    </div>
    <div className="block sm:hidden my-6 pt-28">
      <div className="bg-brand-gray text-white">
        <div className="container py-4">
          <div className="-mt-32 mb-4 px-4">
            <div className="relative rounded-lg overflow-hidden">
              <Img fluid={data.file.childImageSharp.fluid} className="" />
              <div className="text-white absolute right-0 bottom-0 text-3xl font-medium leading-none pb-8 pr-4">
                Diāna Grīnberga
              </div>
            </div>
          </div>
          <p className="text-2xl text-center">
            <span
              style={{
                fontSize: '4.5rem',
                fontFamily: 'Arial',
                lineHeight: 0,
                position: 'relative',
                top: '1.75rem',
                right: '0.25rem',
              }}
            >
              “
            </span>
            Likumi ir jāievēro,
            <br />
            bet, lai tos ievērotu,
            <br />
            tie ir jaizprot!
            <span
              style={{
                fontSize: '4.5rem',
                fontFamily: 'Arial',
                lineHeight: 0,
                position: 'relative',
                top: '1.75rem',
                left: '0.25rem',
              }}
            >
              ”
            </span>
          </p>
        </div>
      </div>
    </div>
    <SectionContainer>
      <p className="text-justify mb-3">Galvenie darbības virzieni:</p>
      <ul className="mb-3">
        <li className="relative pl-5 mb-3">
          <span className="block absolute left-0 top-0 mt-2 ml-1 w-2 h-2 bg-brand-gray2 rounded-full"></span>
          darba tiesības (konsultācija, darba līgumu, kārtības noteikumu u.c.
          dokumentu izstrāde, esošo dokumentu izvērtēšana, darba strīdu un
          domstarpību risināšana);
        </li>
        <li className="relative pl-5 mb-3">
          <span className="block absolute left-0 top-0 mt-2 ml-1 w-2 h-2 bg-brand-gray2 rounded-full"></span>
          darba aizsardzība (ievadapmācības, darba aizsardzības instruktāžu
          izstrāde vai izstrādāto dokumentu, tai skaitā darba vides risku
          faktoru izvērtēšana);
        </li>
        <li className="relative pl-5 mb-3">
          <span className="block absolute left-0 top-0 mt-2 ml-1 w-2 h-2 bg-brand-gray2 rounded-full"></span>
          administratīvās tiesības, tai skaitā valsts pārvaldes iestāžu
          faktiskās rīcības, izdoto administratīvo aktu un piemēroto
          administratīvo sodu apstrīdēšana;
        </li>
        <li className="relative pl-5">
          <span className="block absolute left-0 top-0 mt-2 ml-1 w-2 h-2 bg-brand-gray2 rounded-full"></span>
          semināri un lekcijas.
        </li>
      </ul>
      <p className="text-justify">
        Droši dod ziņu! Atradīšu labāko risinājumu tieši Jūsu situācijai.
      </p>
    </SectionContainer>
    <SectionContainer>
      <div className="text-right">
        <Link
          to="/kontakti"
          className="px-6 py-2 border border-brand-gray text-brand-gray uppercase font-bold rounded-none hover:bg-brand-gray hover:text-white"
        >
          Piesakies uz Konsultāciju &gt;&gt;
        </Link>
      </div>
    </SectionContainer>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "diana_grinberga.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 675, base64Width: 100, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
